import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import vuelidate from "vuelidate";
import VueToast from "vue-toast-notification";
import VModal from "vue-js-modal";
import { store } from "./store/store";
import "@/assets/css/tailwind.css";
import "vue-toast-notification/dist/theme-sugar.css";
import VueConfirmDialog from "vue-confirm-dialog";
import VueSocialSharing from "vue-social-sharing";
import Embed from "v-video-embed";
import ScreenSize from "screen-size-vue";

Vue.use(vuelidate);
Vue.use(VueToast, {
  position: "top-right",
});
Vue.use(VModal);
Vue.use(VueConfirmDialog);
Vue.use(VueSocialSharing);
Vue.use(Embed);
Vue.use(ScreenSize);

Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
new Vue({
  router,
  store,
  vuelidate,
  render: (h) => h(App),
}).$mount("#app");
