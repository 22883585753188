import api from "@/utils/Api";

const state = {
  colors: [],
};
const getters = {};
const mutations = {};
const actions = {
  async getColors() {
    return await api.get("/color");
  },
  async createColor(state, payload) {
    return await api.post("/color", payload);
  },
  async updateColor(state, payload) {
    const { _id, data } = payload;
    delete data._id;
    return await api.patch(`/color/${_id}`, data);
  },

  async deleteColor(state, id) {
    return await api.delete(`/color/${id}`);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
