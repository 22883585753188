import api from "@/utils/Api";

const state = {
  location: [],
};
const getters = {};
const mutations = {};
const actions = {
  async getLocations() {
    return await api.get("/location");
  },
  async createLocation(state, payload) {
    return await api.post("/location", payload);
  },
  async updateLocation(state, payload) {
    const { _id, data } = payload;
    delete data._id;
    return await api.patch(`/location/${_id}`, data);
  },

  async deleteLocation(state, id) {
    return await api.delete(`/location/${id}`);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
