import api from "@/utils/Api";
import { serialize } from "@/utils/serialize";

const state = {};
const getters = {};
const mutations = {};
const actions = {
  async getItems(state, options) {
    const query = serialize(options);
    return await api.get(`/item?${query}`);
  },
  async getTopRated() {
    return await api.get(`/item/top-rated`);
  },

  async getPopular() {
    return await api.get(`/item/popular`);
  },

  async getItem(state, id) {
    return await api.get(`/item/${id}`);
  },

  async getItemBySlug(state, slug) {
    return await api.get(`/item/slug/${slug}`);
  },
  async createItem(state, payload) {
    return await api.post("/item", payload);
  },
  async updateItem(state, payload) {
    const { _id, data } = payload;
    delete data._id;
    return await api.patch(`/item/${_id}`, data);
  },

  async deleteItem(state, id) {
    return await api.delete(`/item/${id}`);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
