import api from "@/utils/Api";

const state = {
  orders: [],
};
const getters = {};
const mutations = {};
const actions = {
  async getOrders() {
    return await api.get("/order");
  },
  async adminGetOrders() {
    return await api.get("/order/all");
  },
  async getOrder(state, id) {
    return await api.get(`/order/${id}`);
  },
  async createOrder(state, payload) {
    return await api.post("/order", payload);
  },
  async updateOrder(state, payload) {
    const { id, data } = payload;
    delete data._id;
    return await api.patch(`/order/${id}`, data);
  },
  async deleteOrder(state, id) {
    return await api.delete(`/order/${id}`);
  },
  async getDeliveryAddresses() {
    return await api.get(`/deliveryAddress`);
  },
  async createDeliveryAddress(state, payload) {
    return await api.post("/deliveryAddress", payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
