import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import color from "./color";
import item from "./items";
import category from "./category";
import location from "./location";
import wishlist from "./wishlist";
import cart from "./cart";
import order from "./order";
import faqs from "./faqs";
import howto from "./howTo";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    color,
    item,
    category,
    location,
    wishlist,
    cart,
    order,
    faqs,
    howto,
  },
  plugins: [
    createPersistedState({
      key: "autogele",
    }),
  ],
});
