import api from "@/utils/Api";

const state = {
  user: {},
  token: null,
  users: [],
};
const getters = {
  isAuthenticated(state) {
    return !!state.user?.firstName;
  },
  getUser(state) {
    return state.user;
  },
  getToken(state) {
    return state.token;
  },
  getName(state) {
    return `${state.user.firstName}`;
  },
};
const mutations = {
  setToken(state, token) {
    return (state.token = token);
  },
  setUser(state, data) {
    return (state.user = data);
  },
};
const actions = {
  async login(state, payload) {
    return await api.post("/auth/login", payload);
  },
  async register(state, payload) {
    return await api.post("/auth/register", payload);
  },
  async getUsers() {
    return await api.get("/user/all");
  },

  async logout({ commit }) {
    commit("setToken", null);
    commit("setUser", null);
    localStorage.clear();
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
