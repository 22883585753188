const state = {
  cart: [],
};
const getters = {
  getCart(state) {
    return state.cart;
  },
  getCartCount(state) {
    return state.cart.length;
  },
};
const mutations = {
  setCart(state, data) {
    return (state.cart = data);
  },
};
const actions = {
  async addItemToCart(state, payload) {
    let currentItems = state.getters.getCart;
    const check = currentItems.find((it) => {
      return it.item._id == payload.item._id;
    });
    if (check) {
      const itemIndex = currentItems.findIndex((it) => {
        return it.color == payload.color;
      });
      currentItems[itemIndex].quantity += 1;
    } else {
      currentItems.push({ ...payload });
    }
    state.commit("setCart", currentItems);
  },
  async removeItemFromCart(state, payload) {
    let currentItems = state.getters.getCart;
    const itemIndex = currentItems.findIndex((it) => {
      return it.color == payload.color && it.item._id == payload.item._id;
    });
    currentItems.splice(itemIndex, 1);
    state.commit("setCart", currentItems);
  },
  async decreaseQuantity(state, payload) {
    let currentItems = state.getters.getCart;
    const itemIndex = currentItems.findIndex((it) => {
      return it.color == payload.color && it.item._id == payload.item._id;
    });
    currentItems[itemIndex].quantity -= 1;
    state.commit("setCart", currentItems);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
