import api from "@/utils/Api";

const state = {
  wishlist: [],
};
const getters = {};
const mutations = {};
const actions = {
  async getWishlists() {
    return await api.get("/wishlist");
  },

  async getWishlist(state, { id, userId }) {
    return await api.get(`/wishlist/${id}/${userId}`);
  },

  async createWishlist(state, payload) {
    return await api.post("/wishlist", payload);
  },
  async updateWishlist(state, payload) {
    const { _id, data } = payload;
    delete data._id;
    return await api.patch(`/wishlist/${_id}`, data);
  },

  async deleteWishlist(state, id) {
    return await api.delete(`/wishlist/${id}`);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
