<template>
  <div class="" id="app">
    <vue-confirm-dialog></vue-confirm-dialog>
    <router-view :key="Date.now()" class="md:mt-auto bg-light-gray" />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.boxed__container {
  max-width: 1350px !important;
  margin: 0 auto;
}

.mobile-side {
  height: 100vh;
  overflow-y: auto;
  display: fixed;
  z-index: 300;
}
</style>
