import api from "@/utils/Api";

const state = {
  faqs: [],
};
const getters = {};
const mutations = {};
const actions = {
  async getFaqs() {
    return await api.get("/faqs");
  },
  async createFaq(state, payload) {
    return await api.post("/faqs", payload);
  },
  async updateFaq(state, payload) {
    const { _id, data } = payload;
    delete data._id;
    return await api.patch(`/faqs/${_id}`, data);
  },

  async deleteFaq(state, id) {
    return await api.delete(`/faqs/${id}`);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
