import Vue from "vue";
import Router from "vue-router";
import { store } from "@/store/store";
// import beforeEach from "../helpers/beforeEach.js";

Vue.use(Router);
const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home.vue"),
  },
  {
    path: "/shop",
    name: "Shop",
    component: () => import("@/views/shop.vue"),
  },
  {
    path: "/wishlist",
    name: "Wishlist",
    meta: { requiresAuth: true },
    component: () => import("@/views/wishlist.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    meta: { requiresAuth: true },
    component: () => import("@/views/cart.vue"),
  },
  {
    path: "/shop/category/:id",
    name: "Shop",
    component: () => import("@/views/shop.vue"),
  },
  {
    path: "/product/:slug",
    name: "Single Product",
    component: () => import("@/views/product.vue"),
  },
  {
    path: "/orders",
    name: "My Order",
    meta: { requiresAuth: true },
    component: () => import("@/views/orders/all.vue"),
  },
  {
    path: "/faqs",
    name: "Faqs",
    component: () => import("@/views/faqs.vue"),
  },
  {
    path: "/how-to",
    name: "How To Videos",
    component: () => import("@/views/how-to.vue"),
  },
  {
    path: "/orders/:id",
    name: "Single Order",
    meta: { requiresAuth: true },
    component: () => import("@/views/orders/single.vue"),
  },
  {
    path: "/auth",
    name: "Auth Home",
    component: () => import("@/views/auth/index.vue"),
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/auth/login.vue"),
      },
      {
        path: "register",
        name: "register",
        component: () => import("@/views/auth/register.vue"),
      },
    ],
  },

  {
    path: "/admin",
    name: "Admin Home",
    component: () => import("@/views/admin/index.vue"),
    meta: { requiresAdmin: true },
    children: [
      {
        path: "",
        name: "admin-dashboard",
        component: () => import("@/views/admin/dashboard.vue"),
      },
      {
        path: "colors",
        name: "colors",
        component: () => import("@/views/admin/colors.vue"),
      },
      {
        path: "locations",
        name: "locations",
        component: () => import("@/views/admin/location.vue"),
      },
      {
        path: "faqs",
        name: "faqs",
        component: () => import("@/views/admin/faqs.vue"),
      },
      {
        path: "how-to",
        name: "howto",
        component: () => import("@/views/admin/howto.vue"),
      },
      {
        path: "category",
        name: "category",
        component: () => import("@/views/admin/category.vue"),
      },
      {
        path: "users",
        name: "Admin Users",
        component: () => import("@/views/admin/users/all.vue"),
      },
      {
        path: "orders",
        name: "Admin Orders",
        component: () => import("@/views/admin/orders/all.vue"),
      },
      {
        path: "orders/:id",
        name: "Admin Single Order",
        component: () => import("@/views/admin/orders/single.vue"),
      },
      {
        path: "items",
        name: "Create Items",
        component: () => import("@/views/admin/items/index.vue"),
      },
      {
        path: "items/create",
        name: "All Items",
        component: () => import("@/views/admin/items/create.vue"),
      },
      {
        path: "items/edit/:id",
        name: "Edit Items",
        component: () => import("@/views/admin/items/create.vue"),
      },
    ],
  },
];

const router = new Router({
  routes,
  mode: "history",
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const loggedIn = !!store?.state?.auth?.token;
  const isAdmin = store?.state?.auth?.user?.role == "ADMIN";
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({
      name: "login",
      query: { redirect: to.fullPath },
    });
  } else if (
    to.matched.some((record) => record.meta.requiresAdmin) &&
    (!loggedIn || !isAdmin)
  ) {
    next({
      name: "login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

// router.beforeEach(beforeEach);
export default router;
