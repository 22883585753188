/* eslint-disable no-prototype-builtins */

export const serialize = (obj, fields = []) => {
    const str = [];
    for (let p in obj) {
        if (obj.hasOwnProperty(p) && p !== 'reset') {
            if (encodeURIComponent(obj[p])) {
                if (fields.length === 0 || fields.includes(encodeURIComponent(p))) {
                    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
                }
            }
        }
    }
    return str.join('&');
}
