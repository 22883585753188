import api from "@/utils/Api";

const state = {
  category: [],
};
const getters = {};
const mutations = {};
const actions = {
  async getCategories() {
    return await api.get("/category");
  },
  async createCategory(state, payload) {
    return await api.post("/category", payload);
  },
  async updateCategory(state, payload) {
    const { _id, data } = payload;
    delete data._id;
    return await api.patch(`/category/${_id}`, data);
  },

  async deleteCategory(state, id) {
    return await api.delete(`/category/${id}`);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
