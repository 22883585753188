import api from "@/utils/Api";

const state = {
  Howtos: [],
};
const getters = {};
const mutations = {};
const actions = {
  async getHowtos() {
    return await api.get("/howto");
  },
  async createHowto(state, payload) {
    return await api.post("/howto", payload);
  },
  async updateHowto(state, payload) {
    const { _id, data } = payload;
    delete data._id;
    return await api.patch(`/howto/${_id}`, data);
  },

  async deleteHowto(state, id) {
    return await api.delete(`/howto/${id}`);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
